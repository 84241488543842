import React from "react";
import Link from "next/link";
import { CTA, CardWrapper, Root, SubHeading, Title } from "./GridLayout.styles";
import { UIElements } from "@/outscal-commons-frontend/Hooks/useAnalyticsClickEvent";

const GridLayoutView = ({
  name,
  headingAlignment,
  heading,
  subHeading,
  col,
  minColCount,
  gridGap,
  listData,
  customCardData,
  redirectionLink,
  actionFunction,
  cardComponent: CardComponent,
  actionText,
  justifyContent,
  alignItems,
  width,
  justifyItems,
  minCardWidth,
}) => {
  return (
    <Root alignment={headingAlignment} width={width}>
      {heading && <Title headingAlignment={headingAlignment}>{heading}</Title>}
      {subHeading && (
        <SubHeading
          headingAlignment={headingAlignment}
          color="textDarkGrey"
          fontSize="sm"
        >
          {subHeading}
        </SubHeading>
      )}
      <CardWrapper
        columns={col}
        minColCount={minColCount}
        gap={`${gridGap}px`}
        alignItems={alignItems}
        justifyContent={justifyContent}
        justifyItems={justifyItems}
        minWidth={minCardWidth}
      >
        {Object.keys(listData).map((key, index) => {
          return (
            <CardComponent
              key={key}
              id={key}
              name={name}
              index={index}
              item={listData[key]}
              customCardData={customCardData}
            />
          );
        })}
      </CardWrapper>
      {redirectionLink && (
        <Link
          href={redirectionLink}
          target="_blank"
          onClick={actionFunction}
          data-analytics={[
            UIElements.BUTTON,
            `show_more${name ? `_${name}` : ""}_button`,
          ]}
          rel="nofollow"
        >
          <CTA>{actionText}</CTA>
        </Link>
      )}
    </Root>
  );
};

export default GridLayoutView;
