import React from "react";
import GridLayoutView from "./GridLayout.view";
import { alignmentMapping } from "./GridLayout.model";

const GridLayout = ({
  name = "",
  col = 1,
  minColCount = 1,
  gridGap = 20,
  heading = "",
  subHeading = "",
  headingAlignment = "center",
  action = {},
  cardComponent: CardComponent,
  listData = {},
  customCardData = {},
  justifyContent = "center",
  alignItems = "center",
  width,
  justifyItems,
  minCardWidth,
}) => {
  const {
    redirectionLink = "",
    actionText = "",
    actionFunction = () => {},
  } = action || {};

  return (
    <GridLayoutView
      name={name}
      headingAlignment={alignmentMapping[headingAlignment]}
      heading={heading}
      subHeading={subHeading}
      col={col}
      minColCount={minColCount}
      gridGap={gridGap}
      listData={listData}
      customCardData={customCardData}
      redirectionLink={redirectionLink}
      actionFunction={actionFunction}
      cardComponent={CardComponent}
      actionText={actionText}
      justifyContent={justifyContent}
      alignItems={alignItems}
      width={width}
      justifyItems={justifyItems}
      minCardWidth={minCardWidth}
    />
  );
};

export default GridLayout;
