import { styled } from "styled-components";
import { Text } from "@/outscal-commons-frontend/Styled/TextStyles";
import { YellowButton } from "@/outscal-commons-frontend/Styled/Buttons";

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${(p) => p.alignment};
  justify-content: center;
  gap: 12px;
  ${(p) => p.width && `width:${p.width}`};
  @media (min-width: ${(p) => p.theme.breakpoints.sm}px) {
    border-radius: ${(p) => p.theme.borderRadius.sm};
  }
`;

export const CardWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: ${(props) =>
    `repeat(${props.columns}, ${
      props.minWidth ? `minmax(${props.minWidth}, max-content)` : "1fr"
    })`};

  gap: ${(props) => props.gap || "12px"};
  justify-content: ${(p) => p.justifyContent || "center"};
  ${(p) => p.justifyItems && `justify-items:${p.justifyItems}`};
  align-items: ${(p) => p.alignItems || "center"};
  margin: 12px 0;
  @media (max-width: 900px) {
    grid-template-columns: repeat(
      ${(props) => Math.floor(props.columns / 2)},
      1fr
    );
  }
  @media (max-width: 600px) {
    grid-template-columns: repeat(${(p) => p.minColCount}, 1fr);
  }
`;

export const Title = styled.h2`
  text-align: ${(p) => p.headingAlignment};
  font-size: ${(p) => p.theme.fontSize.xxxxl};
  font-weight: ${(p) => p.theme.fontWeight.medium};
  @media screen and (max-width: 767px) {
    font-size: ${(p) => p.theme.fontSize.xxxl};
  }
`;

export const SubHeading = styled(Text)`
  text-align: ${(p) => p.headingAlignment};
`;

export const CTA = styled(YellowButton)`
  padding: 8px 20px;
  background-color: ${(p) => p.theme.colors.themeYellow};
  border-radius: ${(p) => p.theme.borderRadius.xxs};
  font-weight: ${(p) => p.theme.fontWeight.medium};
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
`;
